<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col cols="12">
                      <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                          <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('globalTrans.organization') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="expertise.org_id"
                                  :options="orgList"
                                  :disabled="!$store.state.Auth.activeRoleId || $store.state.Auth.activeRoleId !== 1"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Expertise (En)" vid="expertise_en" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="expertise_en"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('elearning_config.expertise') }} {{ $t('globalTrans.enn') }}<span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="expertise_en"
                                v-model="expertise.expertise_en"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Expertise (Bn)" vid="expertise_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="expertise_bn"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('elearning_config.expertise')}} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="expertise_bn"
                                v-model="expertise.expertise_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                      </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { expertiseStore, expertiseUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getEditingData()
      this.expertise = tmp
    }
    this.getOrganizationlist()
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.expertise.org_id = this.$store.state.Auth.authUser.org_id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      expertise: {
        org_id: 0,
        expertise_en: '',
        expertise_bn: ''
      }
    }
  },
  computed: {
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList
      }
  },
  methods: {
    getEditingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.expertise.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${expertiseUpdate}/${this.id}`, this.expertise)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, expertiseStore, this.expertise)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    getOrganizationlist () {
      const orgList = this.$store.state.commonObj.organizationProfileList
      this.organizationList = orgList.map(obj => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    }
  }
}
</script>
